import React from "react";
import { Link } from "gatsby";
import Fade from "react-reveal/Fade";

import styles from "./Blog.module.scss";

import BlogCard from "./BlogCard";

export default function BlogPage({ posts, pageContext }) {
  //Prev and Next pages
  const { currentPage, numPages } = pageContext;
  // const isFirst = currentPage === 1;
  // const isLast = currentPage === numPages;
  // const prevPage =
  //   currentPage - 1 === 1 ? "/blogg" : `blogg/${(currentPage - 1).toString()}`;
  // const nextPage = `blogg/${(currentPage + 1).toString()}`;
  return (
    <section id="blogg" className={styles.section}>
      <div className={styles.container}>
        <h1>Brillantebloggen</h1>
        <div className={styles.gridWrapper}>
          <div className={styles.blogCardGrid}>
            {posts.map(({ node: post }) => (
              <Fade key={post.id}>
                <BlogCard post={post} />
              </Fade>
            ))}
          </div>
        </div>
        <div className={styles.listNavigation}>
          {/*           <div className={styles.prev}>
            {!isFirst && (
              <Link to={prevPage} rel="prev">
                ← Nyere
              </Link>
            )}
          </div> */}
          <div className={styles.pageNumbers}>
            {Array.from({ length: numPages }, (_, i) => (
              <Link
                key={`pagination-number${i + 1}`}
                to={`blogg/${i === 0 ? "" : i + 1}`}
                className={i + 1 === currentPage ? styles.currentPage : ""}
              >
                {i + 1}
              </Link>
            ))}
          </div>
          {/*           <div className={styles.next}>
            {!isLast && (
              <Link to={nextPage} rel="next">
                Eldre →
              </Link>
            )}
          </div> */}
        </div>
        <div style={{ paddingBottom: "9rem" }} />
      </div>
    </section>
  );
}
