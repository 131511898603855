import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout/Layout";
import GenericHelmet from "../components/Helmets/GenericHelmet";
import BreadCrumb from "../components/Layout/Breadcrumb";
import BlogListing from "../components/Blog/Blog";

class IndexPage extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    const location = this.props.location || {};

    console.log(this.props);
    return (
      <Layout location={location}>
        <GenericHelmet location={location} />
        <BreadCrumb input={[{ title: "Blogg" }]} />
        <BlogListing pageContext={this.props.pageContext} posts={posts} />
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query BlogListQuery($skip: Int, $limit: Int) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
            uri
          }
          frontmatter {
            title
            templateKey
            date(formatString: "D. MMMM YYYY", locale: "nb_NO")
            cloudinary_image {
              image
              alt
            }
            cloudinary_image {
              image
              alt
            }
          }
        }
      }
    }
    header: file(relativePath: { eq: "fiolin/fiolin4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default IndexPage;
